@use "breakpoints.scss";
@use "various.scss";

.container {
    display: flex;
    flex-direction: column;

    .newsItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: various.$padding-base-2x;


        gap: various.$padding-base-3x;

        .main {
            width: 100%;
        }

        .sidebar {
            width: 100%;

        }
    }

    .relatedMeta a {
        text-transform: uppercase;
    }

}

.sourceLinks {

    margin-top: various.$padding-base-2x;



    li {
        &:first-child {
            border-top: 1px solid var(--gray250);
        }

        padding: various.$padding-base-2x 0;
        border-bottom: 1px solid var(--gray250);

        a {
            font-size: 90%;
        }
    }
}


/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {

    .sourceLinks {
        border-left: 1px solid var(--gray250);
        padding-left: various.$padding-base-2x;
    }

    .container {


        .newsItem {

            flex-direction: row;

            .main {
                width: 66%;
            }

            .sidebar {
                width: 34%;

            }
        }

        // .relatedMeta {
        //     border-left: 1px solid var(--gray250);
        //     padding-left: various.$padding-base-2x;
        // }
    }
}