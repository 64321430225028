.userWithImage {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.nickAndMeta {
  display: inline-block;
  align-items: flex-start;
  padding-left: 8px;
}
