@use "breakpoints.scss";

/* EqualHeightBoxes.module.css */
.wrapper {
    display: grid;
    grid-row-gap: 32px;
    grid-template-columns: repeat(1, 1fr);
    /* Default value, will be overridden by inline styles */
}


/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {
    .wrapper {
        display: grid;
        grid-auto-rows: 1fr;
        grid-column-gap: 32px;

        grid-row-gap: 32px;


        &.columns2 {
            grid-template-columns: repeat(2, 1fr);
        }

        &.columns3 {
            grid-template-columns: repeat(3, 1fr);
        }

        &.columns4 {
            grid-template-columns: repeat(4, 1fr);
        }

    }
}