@use "various.scss";

.item {
  display: flex;
  margin-bottom: 0.25em;

  span {
    margin-right: various.$padding-base;
  }
}

.item a {
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.item>* {
  align-self: flex-end;
}