@use "breakpoints.scss";
@use "various.scss";

.linkContainer {
  display: flex;
  margin-top: 24px;
  min-width: 300px;
  flex-direction: column;
  gap: 10px;

  h3 {
    margin: 24px auto 0 auto;


  }

  a {
    display: flex;


    button {

      min-height: 42px;
      min-width: 160px;

      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 6px;
        height: auto;
        width: 24px;
      }
    }

  }

  .minorLinksWrapper {
    display: flex;
    align-items: center;

  }

  .appstoreWrapper {

    background-color: black;
    border-radius: var(--borderRadius);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &:hover {
      background-color: lighten(#000, 14%);
    }

    .appstoreButtons {
      padding: 6px;
      height: 38px;
      display: block;
    }
  }



}

.socialLinks {

  margin-top: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .socialLinksWrapper {

    margin: 2px 0 8px 0;

    gap: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--brandLight5);
      border-radius: 40px;
      width: 36px;
      height: 36px;


    }
  }
}

/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {
  .linkContainer {

    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;


    button {
      display: flex;
      padding: 6px;
      min-width: 160px;
      max-width: 160px;
      overflow: hidden;

    }

    .appstoreWrapper {
      width: 160px;
    }
  }
}


html[data-theme="dark"] {
  .appstoreWrapper {
    border: 1px solid var(--gray200);

    &:hover {
      background-color: lighten(#000, 4%);
    }

  }
}

/* Applies to small mobiles (-576px) */
@media screen and (breakpoints.$smDown) {
  .linkContainer {

    a button {
      font-size: 1em;
    }
  }
}