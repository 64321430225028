@use "breakpoints.scss";

.wrapper {
    position: relative;
    min-height: 180px;
    display: flex;
    align-items: center;

    .introText {
        font-size: 1em;
        margin: 6px auto;
        z-index: 9;

        h3 {

            font-size: 1.1em;
        }
    }
}



/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
    .wrapper {
        .introText {
            margin: 16px auto;
            width: 860px;
            text-align: center;

            h3 {
                font-size: 1.3em;

            }
        }
    }
}