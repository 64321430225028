@use "breakpoints.scss";


.image {
    display: none;
}


/* Applies to larger screens (+1200px) */
@media screen and (breakpoints.$xlUp) {
    .image {
        display: block;
        position: absolute;
        bottom: 0;

        &.a2-header-left {
            left: 6px;
        }

        &.a2-header-right {
            right: 10px
        }

        &.a2-drop {
            right: 60px;
            bottom: 10px;
        }

        &.a2-screen {
            right: 6px;
            bottom: 0px;
        }
    }
}

/* Applies to largest screens (+1400px) */
@media screen and (breakpoints.$xxlUp) {
    .image {

        &.a2-header-left {
            left: -40px
        }

        &.a2-header-right {
            right: 80px
        }

        &.a2-screen {
            right: 20px;
            bottom: 0px;
        }

    }
}