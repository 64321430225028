@use "breakpoints.scss";
@use "various.scss";

.container {
    display: flex;
    justify-content: center;

    .listItem {
        margin: 2rem 0;
    }

}



/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
    .list {
        width: 100%;
    }
}