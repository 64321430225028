@use "breakpoints.scss";

/* AdBanner.module.css */


.adBanner {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff !important;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 5px;
    margin: 0 auto;
    position: relative;
}

.adImage {
    display: none;
}

.adText {
    flex-grow: 1;
}

.adTitle {
    font-weight: bold;
    margin: 0;
}

.adDescription {
    font-size: 14px;
    margin: 5px 0;

    em {
        display: inline-block;
        font-size: 14px;
        color: var(--meta);
    }
}

.adUrl {
    font-size: 12px;
    color: #008000;
    text-decoration: underline;
}

.ctaButton {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #fff;
    color: #757575;
    padding: 5px 10px;
    border: 1px solid #999999;
    border-radius: 2px;
    cursor: pointer;
}

.large {

    &.adBanner {
        height: 300px !important;
    }

    .adTitle {
        font-size: 24px;
    }


    .adDescription {
        font-size: 18px;

    }

    .adUrl {
        font-size: 16px;

    }
}

.small {
    &.adBanner {
        border: none;
    }
}


/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
    .adBanner {
        flex-direction: row;
    }

    .adImage {
        display: flex;
        margin-right: 16px;
    }


}