@use "breakpoints.scss";

// .lowerAbout {
//     .lowerScreensAndButtons {
//         margin-top: 22px;
//     }
// }

.infoAbout {
    padding: 0;
    grid-template-areas:
        "heading"
        "desc"
        "likeButton"
        "badge";
}

.lowerAbout {
    padding: 0;

    .lowerButton {
        margin-top: 22px;
        display: flex;
        flex-direction: column;
        gap: 6px;

    }
}

/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {
    .infoAbout {
        gap: 0;
        padding: 20px;
        grid-template-areas:
            "heading likeButton"
            "desc desc"
            "badge badge";
    }

    .itemBoxAbout {
        max-height: 217px;
    }

    .iconDesktopAbout {
        background-color: var(--mainBg);

        // img {
        //     max-width: 190px;
        //     max-height: 190px;
        // }
    }

    .screensAbout {
        padding: 20px 0 0 20px;
    }

    .likeButtonAbout {
        &>div {
            &>span {
                &>span {
                    background-color: var(--ctaLightest);
                    border: 1px solid var(--ctaLight);
                }
            }

        }
    }

    .descAbout {

        max-height: 136px;
        overflow: hidden;


        p {
            padding: 0;
        }

        &>div {
            font-size: 18px;
        }



    }

    .lowerAbout {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0;

        .lowerButton {
            margin-top: 12px;
        }

        .lowerScreensAndButtons {
            justify-self: flex-end;
            min-width: 309px;
            width: 309px;
        }
    }
}