@use "breakpoints.scss";

.wrapper {
  position: relative;
  background-color: var(--brandLight7);
  color: var(--introBoxText);
  border-radius: 0;

  background-position: bottom left, bottom right;
  background-repeat: no-repeat, no-repeat;
  border-left: 1px solid var(--brandLight5);
  border-right: 1px solid var(--brandLight5);
  border-bottom: 1px solid var(--brandLight5);

}

.foodWrapper {
  display: flex;
  justify-content: center;
  padding: 12px 0;
}

/* Applies to large screens (+1200px) */
@media screen and (breakpoints.$xlUp) {
  .wrapper {
    border-radius: 0 0 30px 30px;
    background-image: url('/static/cloud-bg-left.svg'), url('/static/cloud-bg-right.svg');
  }

  html[data-theme="dark"] {
    .wrapper {
      background-image: url('/static/cloud-bg-left-dark.svg'), url('/static/cloud-bg-right-dark.svg');
    }


  }

  .noClouds {
    padding-bottom: 0 !important;
    background-image: none !important;
  }
}