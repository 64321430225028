@use "breakpoints.scss";
@use "various.scss";

.appIntro {
    display: flex;
    position: relative;
}

.appHeader {
    display: flex;
    flex-direction: row;
}

.infoBox {
    display: none;
}

.aboutLinkMobile {
    display: block;
    margin-top: 26px;
}

.appHeaderMeta {
    margin: 24px 0;
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: space-between;

    .shareAndMenu {
        display: flex;
        align-items: center;
    }
}

/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
    .appHeaderMeta {
        flex-direction: row;
        align-items: center;

        .shareAndMenu {
            justify-content: space-between;
            min-width: 380px;
        }
    }

    .infoBox {
        margin-top: 8px;
        margin-left: various.$padding-base-4x;
        width: 380px;
        min-width: 380px;
        display: block;
    }

    .aboutLinkMobile {
        display: none;
    }
}