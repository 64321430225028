.richText {

  a {
    text-decoration: underline;
  }


  h1,
  h2,
  h3 {
    margin-bottom: 0.2em;
    margin-top: 1.5em;
  }

  h2 {
    font-size: 1.53em;

  }

  h3 {
    font-size: 1.13em;

  }

  p:not(:last-child) {
    margin: 0 0 0 0;
  }

  hr {
    border: 0px solid var(--mainBg);
    border-color: var(--gray250);
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    border-top-width: 1px;
  }

  ul,
  ol {
    list-style-type: disc;
    margin-bottom: 1.5em;
    padding-inline-start: 32px;
    display: block;

    li {
      margin: 0 0 0 0;

      p:not(:last-child) {
        margin: 0 0 0 0;
      }

      p {
        padding: 0;
        margin: 0;
      }
    }
  }
}