@use 'breakpoints.scss';



.wrapper {
  background: var(--brandLight7);
  padding: 0.35em 0;
  color: var(--introBoxMeta);
  border-right: 1px solid var(--gray200);
  border-left: 1px solid var(--gray200);

  &.defaultBg {
    background: var(--mainBg);
    padding: 0.5em 0 0 0;

    // .container {
    //   margin-left: 24px;

    // }
  }
}

li.breadcrumb {
  display: inline;
  margin-right: 0.3em;
  font-size: 0.85em;

  a {
    color: inherit;
  }

  &:after {
    content: "►";
    margin-left: 0.5em;
    font-size: 0.66em;
    position: relative;
    top: -1px;
    opacity: 0.7;
  }
}

li.breadcrumb:last-child:after {
  display: none;
}

/* Applies to mobiles (-768px) */
@media screen and (breakpoints.$mdDown) {
  li.breadcrumb {
    /* Fix mobile tap area */
    line-height: 1.8em;
    font-size: 0.8em;
  }
}