@use "breakpoints.scss";

.food {

    text-align: center;

    .largeLB,
    .wideLB,
    .nativeLB {
        min-width: 300px;
        min-height: 320px;
    }

    .largeLBAlternative {
        min-width: 300px;
        min-height: 600px;
    }

    .liveDebugChannels {
        display: none;
        position: absolute;

        z-index: 99999;
        background-color: #f5f9fd;
        border: 1px solid #f1f1f1;
        font-size: 80%;
        padding: 2px;
        width: 300px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    &:hover {


        .liveDebugChannels {
            display: block;
        }
    }

}

/* Applies to large screens (+768px) */
@media screen and (breakpoints.$mdUp) {
    .food {

        .largeLB,
        .wideLB,
        .nativeLB,
        .largeLBAlternative {
            min-width: 728px;
            min-height: 90px;
        }


    }
}

/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
    .food {

        .largeLB,
        .largeLBAlternative {
            min-width: 970px;
            min-height: 280px;
        }

        .wideLB {
            min-width: 970px;
            height: 90px;
        }

        .nativeLB {
            min-width: 970px;
            height: 114px;
            margin-bottom: 40px;
        }
    }
}

/* Applies to large screens (+1200px) */
@media screen and (breakpoints.$xlUp) {
    .food {

        .largeLB,
        .largeLBAlternative {
            min-width: 1176px;
            min-height: 280px;
        }

        .wideLB {
            min-width: 1176px;
            height: 90px;
        }

        .nativeLB {
            min-width: 1176px;
            height: 114px;
            margin-bottom: 40px;
        }
    }
}