.sortWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 12px;
    margin-right: 12px;

    color: var(--metaLight);

    a {
        color: var(--metaLight);

        &.selected {
            color: var(--mainFg);
        }
    }


}