@use "breakpoints.scss";

.itemBox {
    display: flex;
    flex-direction: column;
    position: relative;
}

.iconDesktop {
    display: none;
}

.info {
    order: 3;
    align-self: flex-start;
    padding: 0;
    display: grid;
    row-gap: 8px;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
        "heading"
        "desc"
        "likeButton"
        "badge"
        "meta";

}

.likeButton {
    grid-area: likeButton;
    //margin: 4px 0 4px 0;
    position: relative;
}

.likeButtonLongTitle {
    margin-left: 0 !important;
    margin-top: 0;
    margin-bottom: 10px;
}



.heading {

    grid-area: heading;
    display: flex;
    flex-direction: row;
    align-items: center;


    .iconMobile {
        margin-right: 12px;

        img {
            max-width: 48px;
            max-height: 48px;

        }
    }

    .sponsored {
        margin-left: 2em;
        border: 1px solid var(--brandLight4);
        color: var(--metaLight);
        font-size: 0.7em;
        padding: 0.25em 0.325em 0.2em;
        border-radius: 2px;
        display: inline-block;
    }
}

.meta {
    color: var(--metaLight);
    display: block;

    grid-area: meta;
}

.badges {
    grid-area: badge;
}

.desc {

    grid-area: desc;
}



.screens {
    position: relative;
    order: 1;
    height: auto;
    min-height: 212px;
    justify-content: center;

    // img {
    //     object-fit: contain;
    // }
}


/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {
    .itemBox {
        flex-direction: row;
    }

    .likeButton {
        align-self: center;


    }



    .iconDesktop {
        display: none;
    }

    .info {

        order: 2;
        padding: 20px 20px 20px 0;
        gap: 6px;
        flex-grow: 1;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "heading"
            "desc"
            "likeButton"
            "badge"
            "meta";

        .badges {
            margin-top: 0;
        }

        // h1 {
        //     font-size: 1.8em;
        // }
    }

    .screens {
        order: 3;
        min-width: auto;
        min-height: 197px;
        align-self: flex-start;
    }
}


/* Applies to very large screens (+992px) */
@media screen and (breakpoints.$lgUp) {

    .iconMobile {
        display: none;
    }

    .info {
        padding: 20px;
        grid-template-areas:
            "heading likeButton"
            "desc desc"
            "badge badge"
            "meta meta";
    }

    .likeButton {
        margin-left: 10px;
    }

    .iconDesktop {
        display: block;
        order: 1;
        position: static;
        top: auto;
        padding: 12px;
        align-self: flex-start;
        margin-top: 20px;
        border: 1px solid var(--brandLight4);
        line-height: 0;
        border-radius: 15px;
        // &.expandedBox {
        //     margin-bottom: 20px;
        // }

        img {
            padding: 0;
            border: 0;
            border-radius: 20px;
        }
    }

    .heading {
        width: 100%;
        justify-content: space-between;
    }
}