@use "various.scss";
@use "breakpoints.scss";

.summaryBox {
  padding: 26px 0;

  h3 {
    margin-bottom: various.$padding-base-3x;
  }

  .summary {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .summaryMeta {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding-top: various.$padding-base-3x;
    border-top: 1px solid var(--gray200);

    :global(.profile-link) {
      text-decoration: underline;
      color: var(--mainFg);
    }

    a {
      text-decoration: underline;
      color: var(--mainFg);
    }

    p {
      margin-bottom: various.$padding-base-3x;
    }
  }

  h4 {
    color: var(--meta);
    display: block;
    font-size: var(--fontSizeMediumPx);
    margin-top: 0;
    font-weight: normal;
  }

  ul.metaList {
    list-style: none;
    font-size: 14px;

    li:first-child {
      h4 {
        margin-top: 0;
      }

    }

    li {
      h4 {
        margin-top: 16px;
      }

    }
  }

  .appLists {
    a {
      color: var(--mainFg);
      text-decoration: underline;
      font-size: var(--fontSizeMediumPx);
    }
  }

  ul.iconList {
    list-style: none;

    li {
      font-size: var(--fontSizeMediumPx);
      display: flex;
      align-items: center;

      svg {
        color: var(--gray500);
      }
    }
  }
}

/* Applies to medium screens (+768px) */
@media screen and (breakpoints.$mdUp) {

  .summaryBox {


    .summary {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      gap: 40px;

    }

    .summaryMeta {
      display: flex;
      flex-direction: row;
      gap: 40px;
      align-items: baseline;
      padding-top: various.$padding-base-3x;
      border-top: 1px solid var(--gray200);

    }
  }

}

/* Applies to medium screens (+992px) */
@media screen and (breakpoints.$lgUp) {

  .summaryBox {
    .summary {
      display: unset;
    }

    .summaryMeta {
      display: unset;
    }

    &.fullWidth {

      .summary {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        gap: 40px;

      }

      .summaryMeta {
        display: flex;
        flex-direction: row;
        gap: 40px;
        align-items: baseline;
        padding-top: various.$padding-base-3x;
        border-top: 1px solid var(--gray200);

      }
    }
  }
}