@use 'breakpoints.scss';

$defaultPadding: 22px;

.banner {
    background-color: var(--mainBg);
    border: 1px solid var(--gray300);

    border-radius: 12px;

    width: 100%;
    height: 280px;
    display: flex;
    position: relative;

    @include breakpoints.mobile {
        flex-direction: column;
        width: 100%;
        height: auto;
    }


    // @include breakpoints.tabletLarge {
    //     width: 1140px;
    // }
}

.linkContainer {
    display: flex;
    flex-grow: 1;
}

.info {
    font-size: 0.60em;
    position: absolute;
    top: -1px;
    right: 18px;
    background-color: var(--gray200);
    padding: 2px 2px 0 2px;
    border: 1px solid var(--gray300);
    color: var(--popBrand);
    font-weight: bold;
}


.screenshot {
    max-height: 278px;
    border-right: 1px solid var(--gray300);
    border-radius: 12px 0 0 12px;
    background-image: linear-gradient(to bottom, var(--gray200) 80%, transparent 100%);

    @include breakpoints.mobile {
        max-height: none;
        width: 100%;
        border-right: none;
        border-radius: 12px 12px 0 0;

    }
}


.content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;


    @include breakpoints.mobile {
        flex-direction: column;
        width: 100%;



    }




    // @include breakpoints.tabletLarge {
    //     width: 1140px;
    // }
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    min-height: 280px;

    @include breakpoints.mobile {
        min-height: auto;
    }
}

.titleWrapper {
    padding-top: $defaultPadding;
    padding-left: $defaultPadding;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: $defaultPadding;

    .promoAndHeader {
        display: flex;
        flex-direction: column;

        .promo {
            margin-top: 0;
        }
    }

    h1 {
        margin: 0;
        font-size: 34px !important;

        @include breakpoints.mobile {
            font-size: 30px !important;
        }
    }

    @include breakpoints.mobile {
        padding-left: 0;
        flex-direction: column;
        margin-top: -100px;
        gap: 8px;

        .promoAndHeader {
            display: flex;
            align-items: center;
            flex-direction: column;

            .promo {
                margin: 0 auto;
                text-align: center;
                max-width: 90%;
            }
        }
    }
}

.iconWrapper {
    border: 1px solid var(--brandLight5);
    border-radius: 12px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 96px;
    min-height: 96px;

    @include breakpoints.mobile {
        background-color: #fff;
        margin-top: 10px;
        min-width: 76px;
        min-height: 76px;
        max-height: 76px;
        max-width: 76px;

        img {
            min-width: 50px;
            min-height: 50px;
            max-height: 50px;
            max-width: 50px;
            border-radius: 0;
        }
    }

    @include breakpoints.tabletSmall {
        min-width: 76px;
        min-height: 76px;
        max-height: 76px;
        max-width: 76px;

    }

}

.text {
    flex: 1;
    padding-left: $defaultPadding;

    padding-right: $defaultPadding;

    text-align: left;

    p {
        margin: $defaultPadding / 2 0;
        font-size: 18px;

        @include breakpoints.mobile {
            margin: $defaultPadding / 6 12px;
            font-size: 16px;
        }


    }

    @include breakpoints.mobile {
        text-align: center;
        padding-left: 0;
        margin-bottom: 12px;
        padding-right: 0;

        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.learnMore {


    color: var(--linkColor);

    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    svg {
        margin-left: 4px;
    }

    @include breakpoints.mobile {
        padding: 6px 6px 6px 6px;
        text-align: right;
        font-size: 0.9em;
    }
}


.bottom {
    border-top: 1px solid var(--gray300);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 54px;
    padding: 0px $defaultPadding;
    display: flex;
    align-items: center;

    @include breakpoints.mobile {
        padding: 10px 12px;
        min-height: auto;
        max-height: none;
    }

}