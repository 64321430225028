@use 'breakpoints.scss';
@use 'various.scss';

.item {
    display: flex;
    gap: 6px;


    h3 {
        margin-bottom: 0;
    }

    span {
        color: var(--metaLight);
    }

    img,
    [data-dummy] {
        margin-top: 8px;

        margin-right: various.$padding-base;
    }
}

.compact {
    margin-top: 0;
    line-height: 18px;



    img,
    [data-dummy] {
        margin-top: 5px;
        margin-right: 0;
    }
}


/* Applies to large screens (+992px) */
@media screen and (breakpoints.$lgUp) {
    .item {
        gap: 12px;
    }
}