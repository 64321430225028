@use 'breakpoints.scss';
@use 'various.scss';

.colGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 28px;
}


/* Applies to large screens (+992px) */
@media screen and (breakpoints.$mdUp) {
    .colGrid2 {
        grid-template-columns: repeat(2, 1fr);
    }

    .colGrid3 {
        grid-template-columns: repeat(3, 1fr);
    }

    .colGrid4 {
        grid-template-columns: repeat(4, 1fr);
    }
}